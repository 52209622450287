<template>
  <div>
    <v-row>
      <!-- basic -->
      <v-col cols="12">
        <v-card>
          <div class="w-full boxed-container">
            <div class="mr-6 mb-4 align-center">
              <v-row>
                <v-col sm="8" cols="12">
                  <v-card-title>Packages</v-card-title>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Id</th>
                  <th class="text-center">Package Type</th>
                  <th class="text-center">Image</th>
                  <th class="text-center">Cost</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td class="text-center">
                    {{ item.package_type }}
                  </td>
                  <td class="justify-center d-flex">
                    <v-img max-width="100" contain :src="item.image"></v-img>
                  </td>
                  <td class="text-center">
                    {{ item.price }}
                  </td>
                  <td class="text-center">
                    <v-btn @click="$router.push({ name: 'PackageEdit', params: { id: item.id } })" color="#56ca00" icon>
                      <v-icon>
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import Pagination from '../../components/Pagination.vue'
import { format, parseISO } from 'date-fns'
export default {
  name: 'UserList',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      paginate: {},
      search: null,
      load: true
    }
  },
  created() {
    this.init(1)
  },
  methods: {
    init(page) {
      var url = 'packages?page=' + page
      this.$api.get(url).then(response => {
        this.items = response.data.data
        this.paginate = response.data.pagination
        this.items.forEach(val => {
          if (val.image != null) {
            val.image = process.env.VUE_APP_MEDIA_URL + val.image
          }
        })
        this.load = false
      })
    },
    onPageChange(page) {
      this.init(page)
    },
    formatDate(date){
      return format(parseISO(new Date(date).toISOString()), 'dd-MM-yyyy')
    }
  },
}
</script>
